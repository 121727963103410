<template>
  <div class="details-step">
    <div class="component-title mem-font--checkout-view__section-title">
      {{ $t("order_details") }}
    </div>
    <div class="hidden-slot">
      <slot name="refundProtect" />
    </div>

    <component-skeleton v-if="!isLoaded" />
    <template v-else>
      <!-- tickets -->
      <template v-if="checkoutList.length !== 0">
        <div v-for="(ticket, index) in checkoutList" :key="index">
          <ticket-card
            :single="singleCard"
            :ticket="ticket"
            :validate="validateCard"
            :country="country"
            @edit-card="collapseCard"
            @end-validation="validateCard = false"
            @quantity-change="onQuantityChange"
            @submit-card="onCardSubmit"
            @addons-list-change="$emit('on-details-change')"
          />
        </div>
      </template>

      <!-- spartan plus ticket -->
      <ticket-spartan-plus
        v-if="spartanPlusTicket"
        :ticket="spartanPlusTicket"
        @on-change="$emit('on-details-change')"
      />

      <div class="form-wrap" v-if="showFormsSection" ref="formsValidationPoint">
        <!-- billing address -->
        <template v-if="checkoutParams.requireFullBillingAddress">
          <div class="form-title">{{ $t("billing_address_form_title") }}</div>
          <address-form
            class="billing-address-form"
            :address="checkoutBillingAddress"
            :validate="validateBillingAddressForm"
          />
        </template>
        <!-- purchaser questions -->
        <template v-if="checkoutParams.purchaserQuestions !== 0">
          <v-form ref="purchaserQuestionsForm" class="purchaser-questions-form">
            <div
              class="question-item"
              v-for="(question, index) in checkoutParams.purchaserQuestions"
              :key="index"
            >
              <div class="form-title">{{ question.questionText }}</div>
              <v-text-field
                light
                color="#8D8D8D"
                class="form-field"
                v-model.trim="question.answerModel"
                :label="question.questionText"
                :rules="[
                  (v) => !question.required || !!v || $t('field_required'),
                ]"
              ></v-text-field>
            </div>
          </v-form>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import config from "@/config";

import { findIndex, findLastIndex, find, some, isEmpty } from "lodash";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    "component-skeleton": () => import("./ComponentSkeleton.vue"),
    "ticket-card": () => import("./TicketCard.vue"),
    "ticket-spartan-plus": () => import("./TicketSpartanPlus.vue"),
    "address-form": () => import("./AddressForm.vue"),
  },
  data: () => ({
    validateCard: false,
    validateBillingAddressForm: false,
  }),
  props: {
    validate: {
      type: Boolean,
      default: false,
    },
    country: String,
    isLoaded: Boolean,
  },
  computed: {
    ...mapGetters([
      "checkoutList",
      "checkoutParams",
      "checkoutRegion",
      "checkoutBillingAddress",
      "userSubscription",
    ]),
    singleCard() {
      return this.checkoutList.length === 1;
    },
    showFormsSection() {
      let { requireFullBillingAddress, purchaserQuestions } =
        this.checkoutParams;
      return requireFullBillingAddress || purchaserQuestions.length !== 0;
    },
    currentCard() {
      return find(
        this.checkoutList,
        (card) => card.accountTicketCard.collapsed
      );
    },
    uncompletedCard() {
      return find(
        this.checkoutList,
        (card) => !card.accountTicketCard.completed
      );
    },
    billingAddressFilled() {
      // check TS settings config
      if (!this.checkoutParams.requireFullBillingAddress) return true;

      let { address, country, city, zip } = this.checkoutBillingAddress;
      let requiredFields;
      // if (this.statesList)
      //   requiredFields = { address, country, state, city, zip };
      requiredFields = { address, country, city, zip };

      return !some(requiredFields, isEmpty);
    },
    purchaserQuestionsFilled() {
      let { purchaserQuestions } = this.checkoutParams;
      if (purchaserQuestions.length === 0) return true;

      let emptyField = find(purchaserQuestions, (question) => {
        return question.required && !question.answerModel;
      });
      if (!emptyField) return true;

      return false;
    },
    spartanPlusTicket() {
      if (this.userSubscription && this.userSubscription.isActive) return;

      return config.checkout[this.checkoutRegion.toLowerCase()]
        ?.spartanPlusTicket;
    },
  },
  watch: {
    validate: {
      // "Checkout" button click watcher"
      handler(val) {
        if (!val) return;
        // validate billing address form
        this.validateBillingAddressForm = true;
        this.$nextTick(() => {
          this.validateBillingAddressForm = false;
        });
        // validate purchaser questions form
        this.$refs.purchaserQuestionsForm?.validate();

        // validate current card
        if (this.currentCard && !this.currentCard.accountTicketCard.completed) {
          this.validateCard = true;
          return;
        }
        // All tickets filled & valid - next step
        this.finishStep();
      },
    },
  },
  methods: {
    ...mapActions(["generateOrderPayload", "removeTicket"]),
    ...mapMutations(["updateTicketCard"]),
    // Tickets quantity change
    async onQuantityChange(data) {
      let { ticket, quantity } = data;
      this.updateTicketCard({
        ticketTypeId: ticket.id,
        field: "quantity",
        value: quantity,
      });
      if (quantity === 0) {
        // set card to "complete" state, to collapse next one
        this.updateTicketCard({
          ticketTypeId: ticket.id,
          field: "completed",
          value: true,
        });
        this.collapseCard(ticket);
        await this.removeTicket(ticket);
      }
      this.$emit("on-details-change");
    },
    onCardSubmit(cardData) {
      let { ticket } = cardData;

      this.updateTicketCard({
        ticketTypeId: ticket.id,
        field: "valid",
        value: true,
      });
      this.updateTicketCard({
        ticketTypeId: ticket.id,
        field: "completed",
        value: true,
      });

      // collapse current(filled) card and show next(not filled/valid card)
      if (this.uncompletedCard) return this.collapseCard(ticket);

      this.$nextTick(() => {
        // one ticket
        if (this.singleCard) return this.finishStep();

        // multiple tickets
        // "Checkout" button click event
        if (this.validateCard) return this.finishStep();

        // Ticket card "Save" button click event
        this.validateCard = false;
        this.collapseCard(ticket);
      });
    },
    // cards collapse algorithm
    collapseCard(ticket) {
      // if user (for some reason) wants to collapse next card
      if (!ticket.accountTicketCard.collapsed) {
        // close collapsed card
        let collapsedCard = find(
          this.checkoutList,
          (listItem) => listItem.accountTicketCard.collapsed
        );
        this.updateTicketCard({
          ticketTypeId: collapsedCard?.id,
          field: "collapsed",
          value: false,
        });
        // collapse targeted card
        this.updateTicketCard({
          ticketTypeId: ticket.id,
          field: "collapsed",
          value: true,
        });
        return;
      }

      let currentCardIndex = findIndex(this.checkoutList, { id: ticket.id });
      let lastCardIndex = findLastIndex(this.checkoutList);

      // open next not completed card in list
      if (currentCardIndex < lastCardIndex) {
        let nextCard = find(
          this.checkoutList,
          (listItem) => !listItem.accountTicketCard.completed,
          currentCardIndex
        );
        // console.log("Next card obj: ", nextCard.id);
        this.updateTicketCard({
          ticketTypeId: nextCard?.id,
          field: "collapsed",
          value: true,
        });
      }
      // check if list has not completed cards in top
      if (currentCardIndex === lastCardIndex) {
        let nextCard = find(
          this.checkoutList,
          (listItem) => !listItem.accountTicketCard.completed
        );
        this.updateTicketCard({
          ticketTypeId: nextCard?.id,
          field: "collapsed",
          value: true,
        });
      }

      // close current card
      // let currentCard = find(this.checkoutList, { id: ticketId });
      this.updateTicketCard({
        ticketTypeId: ticket.id,
        field: "collapsed",
        value: false,
      });
    },
    finishStep() {
      console.log("Finish step");
      // validate billing address
      if (!this.billingAddressFilled) {
        this.validateCard = false;
        this.validateBillingAddressForm = true;
        this.$nextTick(() => {
          this.validateBillingAddressForm = false;
        });
        this.$refs.formsValidationPoint.scrollIntoView({
          behavior: "smooth",
        });
        return;
      }
      // validate purchaser questions
      if (!this.purchaserQuestionsFilled) {
        this.validateCard = false;
        this.$refs.purchaserQuestionsForm.validate();
        this.$refs.formsValidationPoint.scrollIntoView({
          behavior: "smooth",
        });
        return;
      }
      this.$emit("change-step");
    },
  },
  async mounted() {},
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;
.details-step {
  overflow: hidden;
  @media screen and (max-width: $mobile-view) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &::after {
    content: " ";
    display: flex;
    height: 1px;
    // margin-top: 40px;
    margin-bottom: 48px;
    background-color: #cfcfcf;
    @media screen and (max-width: $mobile-view) {
      display: none;
    }
  }
  .component-title {
    // margin-bottom: 60px;
    margin-bottom: 30px;
    @media screen and (max-width: $mobile-view) {
      display: none;
    }
  }
  .hidden-slot {
    display: none;
  }
  .form-wrap {
    padding-bottom: 40px;
    &::before {
      content: " ";
      display: flex;
      height: 1px;
      margin-bottom: 40px;
      background-color: #cfcfcf;
      @media screen and (max-width: $mobile-view) {
        display: none;
      }
    }
    @media screen and (max-width: $mobile-view) {
      background-color: #ffffff;
      padding: 24px;
    }
    .form-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 21px; /* 116.667% */
      margin-bottom: 20px;
    }
    .billing-address-form {
      ~ .purchaser-questions-form {
        margin-top: 20px;
      }
    }
    .purchaser-questions-form {
      display: grid;
      grid-template-columns: 50%;
      @media screen and (max-width: $mobile-view) {
        grid-template-columns: 1fr;
      }
      .question-item {
        ~ .question-item {
          margin-top: 20px;
        }
      }
      .form-field {
        ::v-deep(.v-label) {
          text-transform: capitalize;
          font-weight: 500;
          color: #8d8d8d;
        }
      }
    }
    .form-divider {
      content: " ";
      display: flex;
      height: 1px;
      margin-top: 40px;
      margin-bottom: 48px;
      background-color: #cfcfcf;
      @media screen and (max-width: $mobile-view) {
        display: none;
      }
    }
  }
}
</style>