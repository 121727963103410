<template>
  <div class="extras-step">
    <div class="component-title mem-font--checkout-view__section-title">
      {{ $t("extras") }}
    </div>

    <slot name="refundProtect" />
    <div v-if="hasRefundProtectSlot" class="widget-divider"></div>

    <div class="foundation-section" v-if="showFoundation">
      <div
        v-html="spartanFoundationQuestion.moreInfoContents"
        class="question-body"
      ></div>
      <div class="question-answers">
        <div
          class="question-answer"
          :class="{
            'is-selected': spartanFoundationQuestionAnswer.id === answer.id,
          }"
          v-for="(answer, index) in spartanFoundationQuestion.questionAnswers"
          :key="index"
          @click="setFoundationAnswer(answer)"
        >
          <div>{{ answer.answer }}</div>
        </div>
      </div>
    </div>

    <div class="charity-section" v-if="showCharity">
      <template v-if="checkoutRegion === 'US'">
        <div class="section-subtitle">{{ $t("checkout_charity_title") }}</div>

        <div class="charity-description">
          <span class="section-text">{{
            $t("checkout_charity_description")
          }}</span>
          <v-dialog
            class="mem-dialog"
            v-model="charityDialog"
            :max-width="480"
            overlay-color="#000000"
            overlay-opacity="0.54"
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="modal-link" v-bind="attrs" v-on="on">{{
                $t("charity_description_link")
              }}</span>
            </template>
            <div class="charity-dialog">
              <div class="dialog-section">
                <img
                  src="@/assets/checkout_charity_modal_img1.svg"
                  alt="Find"
                />
                <div class="section-title">
                  {{ $t("charity_popup_passion_title") }}
                </div>
                <div class="section-text">
                  {{ $t("charity_popup_passion_description") }}
                </div>
              </div>
              <div class="dialog-section">
                <img src="@/assets/checkout_charity_modal_img2.svg" alt="Get" />
                <div class="section-title">
                  {{ $t("charity_popup_instructions_title") }}
                </div>
                <div class="section-text">
                  {{ $t("charity_popup_instructions_description") }}
                </div>
              </div>
              <div class="dialog-section">
                <img src="@/assets/checkout_charity_modal_img3.svg" alt="Go" />
                <div class="section-title">
                  {{ $t("charity_popup_make_difference_title") }}
                </div>
                <div class="section-text">
                  {{ $t("charity_popup_make_difference_description") }}
                </div>
              </div>
              <mem-button
                class="dialog-button"
                @click="charityDialog = false"
                >{{ $t("charity_popup_button") }}</mem-button
              >
            </div>
          </v-dialog>
        </div>

        <div class="charities-list">
          <div
            class="charities-list-item"
            :class="{
              'is-selected':
                checkoutFundraisingCompany.beneficiaryAccountId ===
                company.beneficiaryAccountId,
            }"
            v-for="(company, index) in fundraisingCompanies"
            :key="index"
            @click="setFundraisingCompany(company)"
          >
            <img
              v-if="company.hasImage"
              :src="company.logo"
              :alt="company.name"
              class="company-image"
            />
            <div v-else>{{ $t(company.descriptionKey) }}</div>
          </div>
          <div
            class="charities-list-item"
            :class="{ 'is-selected': noFundraising }"
            @click="setFundraisingCompany()"
          >
            <div>{{ $t("charity_select_no_thanks") }}</div>
          </div>
        </div>

        <i18n
          class="section-subline"
          path="checkout_charity_agreement_text"
          tag="span"
        >
          <a
            href="https://www.spartan.com/en/about/privacy-policy"
            target="_blank"
          >
            {{ $t("privacy_policy") }}</a
          >
        </i18n>
      </template>
      <template v-else>
        <div class="section-subtitle">{{ $t("checkout_charity_title") }}</div>
        <fundraising-companies-section
          :descriptionTextKey="'givestar_description'"
          :privacyPolicyTextKey="'givestar_policy_text'"
          :companiesList="fundraisingCompanies"
          :amountList="fundraisingAmountList"
          :errors="errors"
          @on-amount-change="errors.fundraisingAmount = false"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { CHECKOUT_CONFIG } from "@/lists";
import { isEmpty, toNumber } from "lodash";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "fundraising-companies-section": () =>
      import("./components/FundraisingCompaniesSection.vue"),
  },
  data: () => ({
    noFundraising: false,
    charityDialog: false,
    errors: {
      fundraisingAmount: false,
    },
  }),
  props: {
    submitExtras: Boolean,
  },
  computed: {
    ...mapGetters([
      "checkoutRegion",
      "spartanFoundationQuestion",
      "spartanFoundationQuestionAnswer",
      "checkoutFundraisingCompany",
    ]),
    fundraisingCompanies() {
      return (
        CHECKOUT_CONFIG[this.checkoutRegion.toLowerCase()]
          ?.fundraisingCompanies || []
      );
    },
    fundraisingAmountList() {
      return (
        CHECKOUT_CONFIG[this.checkoutRegion.toLowerCase()]
          ?.fundraisingAmountList || []
      );
    },
    showFoundation() {
      // if (this.checkoutRegion !== "US") return;
      return !isEmpty(this.spartanFoundationQuestion);
    },
    showCharity() {
      return !isEmpty(this.fundraisingCompanies);
    },
    hasRefundProtectSlot() {
      return !!this.$slots.refundProtect;
    },
  },
  watch: {
    submitExtras: {
      handler(val) {
        if (!val) return;
        // 
        if (isEmpty(this.checkoutFundraisingCompany))
          return this.$emit("change-step");
        // No need to validate
        if (isEmpty(this.fundraisingAmountList))
          return this.$emit("change-step");
        // Amount item is not selected
        if (!this.checkoutFundraisingCompany.total) {
          this.errors.fundraisingAmount = true;
          return;
        }
        return this.$emit("change-step");
      },
    },
  },
  methods: {
    ...mapMutations([
      "setSpartanFoundationQuestionAnswer",
      "setCheckoutFundraisingCompany",
    ]),
    setFoundationAnswer(answer) {
      let answerAmount = toNumber(answer.addOnAmount);
      let previousAnswerAmount = toNumber(
        this.spartanFoundationQuestionAnswer.addOnAmount || 0
      );

      if (this.spartanFoundationQuestionAnswer.id === answer.id) return;
      if (answerAmount === 0 && previousAnswerAmount === 0) {
        this.setSpartanFoundationQuestionAnswer(answer);
        return;
      }

      this.setSpartanFoundationQuestionAnswer(answer);
      this.$emit("on-details-change");
    },
    setFundraisingCompany(company) {
      if (company) {
        this.noFundraising = false;
        this.setCheckoutFundraisingCompany(company);
        return;
      }
      this.noFundraising = true;
      this.setCheckoutFundraisingCompany({});
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
$mobile-view: 1024px;
$mobile-padding: 24px;
.charity-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 52px;
  padding-bottom: 48px;
  color: #000000;
  background-color: #ffffff;
  .dialog-section {
    max-width: 304px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    img {
    }
    .section-title {
      margin-top: 16px;
      margin-bottom: 10px;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 21px; /* 116.667% */
    }
    .section-text {
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      line-height: 22px; /* 146.667% */
    }
  }
  .dialog-button {
    margin-top: 16px;
  }
}
.extras-step {
  padding-bottom: 130px;
  @media screen and (max-width: $mobile-view) {
    background-color: #ffffff;
    padding-top: 40px;
    padding-bottom: 24px;
  }
  .component-title {
    margin-bottom: 52px;
    @media screen and (max-width: $mobile-view) {
      display: none;
    }
  }
  .section-subtitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;

    margin-bottom: 22px;
  }
  .section-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 133.4%; /* 21.344px */
    @media screen and (max-width: $mobile-view) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .section-subline {
    font-size: 12px;
    line-height: 16px;
  }
  .widget-divider {
    &::after {
      content: " ";
      display: flex;
      height: 1px;
      background-color: #e2e2e2;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  .foundation-section {
    &::after {
      content: " ";
      display: flex;
      height: 1px;
      background-color: #e2e2e2;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @media screen and (max-width: $mobile-view) {
      padding-left: 24px;
      padding-right: 24px;
    }
    .question-body {
    }
    .question-answers {
      margin-top: 22px;
      display: grid;
      grid-template-columns: repeat(auto-fill, 120px);
      gap: 8px;
      @media screen and (max-width: $mobile-view) {
        margin-top: 30px;
        grid-template-columns: 1fr 1fr;
      }
      .question-answer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
        height: 74px;
        border-radius: 12px;
        border: 1px solid #d8d8d8;
        @include cursorPointer;
        &.is-selected {
          border: 1px solid #cf1019;
        }
        @media screen and (max-width: $mobile-view) {
          height: 80px;
        }
      }
    }
  }
  .charity-section {
    @media screen and (max-width: $mobile-view) {
      // padding-bottom: 24px;
      padding-left: 24px;
      padding-right: 24px;
    }

    .charities-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, 154px);
      gap: 8px;
      margin-top: 22px;
      margin-bottom: 22px;
      @media screen and (max-width: $mobile-view) {
        grid-template-columns: 1fr 1fr;
        margin-top: 30px;
        margin-bottom: 26px;
      }
      .charities-list-item {
        position: relative;
        height: 100px;
        @media screen and (max-width: $mobile-view) {
          height: 80px;
        }
        border-radius: 12px;
        border: 1px solid #d8d8d8;
        padding-left: 24px;
        padding-right: 24px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;

        font-size: 12px;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        @include cursorPointer;
        &.is-selected {
          border-color: #cf1019;
        }
        .company-image {
          transform: scale(0.2);
          position: absolute;
        }
      }
    }
    a {
      color: #4f4f4f;
    }
    .charity-description {
      .modal-link {
        margin-left: 4px;
        text-decoration: underline;
        @include cursorPointer;
        @media screen and (max-width: $mobile-view) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>