<template>
  <div class="payment-step">
    <div class="component-title mem-font--checkout-view__section-title">
      {{ $t("payment_information_title") }}
    </div>
    <div class="hidden-slot">
      <slot name="refundProtect" />
    </div>

    <div class="component-subtitle">{{ $t("payment_information_method") }}</div>

    <template>
      <div v-if="killerDeal && !isLoading" class="killer-deal">
        <img src="@/assets/piggy.svg" alt="piggy" />
        <span>{{ $t("zero_payment_description") }}</span>
      </div>
      <div
        v-else-if="paymentProcessor === 'PayU' && !isLoading"
        class="payu-form"
      >
        <img src="@/assets/logo_payu.svg" alt="PayU" />
        <span class="form-title">{{ $t("checkout_payment_payu_text") }}</span>
      </div>
      <template v-else>
        <component-skeleton v-if="isLoading" />
        <template v-else>
          <payment-methods-list @on-payment-method-change="setPaymentMethod" />
          <template v-if="showStripeElementsForm && stripeClientSecret">
            <div class="payment-form" id="payment-form">
              <div ref="paymentElement" id="payment-element"></div>
            </div>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>
<script>
import { loadStripe } from "@stripe/stripe-js";

// import config from "@/config";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    "payment-methods-list": () => import("./PaymentMethodsList.vue"),
    "component-skeleton": () => import("./ComponentSkeleton.vue"),
  },
  data: () => ({
    stripe: undefined,
    elements: undefined,
    paymentMethodDetails: undefined,
    showStripeElementsForm: false,
    isLoading: true,
    isLoadedPaymentMethods: false,
  }),
  props: {
    submitPayment: {
      type: Boolean,
      default: false,
    },
    paymentProcessor: String,
    redirectUrl: String,
    killerDeal: Boolean,
    stripeConfig: Object,
  },
  computed: {
    ...mapGetters(["orderData", "gtmConversionData", "checkoutParams"]),
    stripeClientSecret() {
      let { stripePaymentIntent } = this.orderData;
      let { payment_intent_client_secret } = this.$route.query;

      return stripePaymentIntent?.client_secret || payment_intent_client_secret;
    },
  },
  watch: {
    submitPayment: {
      async handler(val) {
        if (!val) return;
        this.$emit("set-process-status", true);
        try {
          if (this.killerDeal) {
            await this.postOrder({
              canRetry: false,
              paymentProcessor: this.paymentProcessor,
              redirectUrl: this.redirectUrl,
            });
            await this.completeOrder();
            await this.deleteCart();
          } else if (this.paymentProcessor === "PayU") {
            this.submitPayuForm();
            return;
          } else {
            await this.validateOrder();
            let paymentIntentId = await this.confirmStripePayment();
            await this.completeOrder(paymentIntentId);
          }
          this.$emit("gtm-push", "Conversion");
          this.$emit("change-step");
          this.$emit("set-process-status", false);
        } catch (error) {
          console.log(error);
          this.$emit("on-error", error);
          this.$emit("set-process-status", false);
        }
      },
    },
    paymentMethodDetails: {
      handler(value) {
        let { paymentMethodType } = value;
        console.log("Payment method changed", paymentMethodType, value);

        // stripe New card
        if (paymentMethodType === "new-card") {
          this.showStripeElementsForm = true;
          this.$nextTick(() => {
            this.initStripeElements();
          });
          return;
        }
        // sezzle & saved card
        this.showStripeElementsForm = false;
      },
    },
    stripeClientSecret: {
      handler(secret, oldSecret) {
        console.log("New secret", secret);
        console.log("Old secret", oldSecret);
        if (!this.showStripeElementsForm || !secret) return;
        this.initStripeElements();
      },
    },
    killerDeal: {
      async handler(isActive) {
        if (isActive) return;

        if (!this.isLoadedPaymentMethods)
          return await this.loadPaymentMethods();
      },
    },
  },
  methods: {
    ...mapActions([
      "getStripePaymentMethods",
      "postOrder",
      "validateOrder",
      "completeOrder",
      "deleteCart",
    ]),
    async setPaymentMethod(paymentMethod) {
      this.paymentMethodDetails = paymentMethod;
      this.$emit("on-payment-method-change", paymentMethod.paymentMethodType);
    },
    async confirmStripePayment() {
      if (!this.stripe)
        this.stripe = await loadStripe(this.stripeConfig.publishableKey, {
          stripeAccount: this.stripeConfig.accountId,
        });
      let { paymentMethodType } = this.paymentMethodDetails;

      if (paymentMethodType === "new-card") {
        const { paymentIntent, error } = await this.stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements: this.elements,
          redirect: "if_required",
          confirmParams: {
            return_url: this.redirectUrl,
          },
        });
        if (error) return Promise.reject({ title: error.message });

        return paymentIntent.id;
      }

      if (paymentMethodType === "saved-card") {
        console.log("Saved card payment", this.stripeClientSecret);

        const { paymentIntent, error } = await this.stripe.confirmCardPayment(
          this.stripeClientSecret,
          {
            payment_method: this.paymentMethodDetails.id,
          }
        );
        if (error) return Promise.reject({ title: error.message });
        return paymentIntent.id;
      }
    },
    async initStripeElements() {
      console.log("Init stripe elements...", this.stripeClientSecret);
      if (!this.stripe)
        this.stripe = await loadStripe(this.stripeConfig.publishableKey, {
          stripeAccount: this.stripeConfig.accountId,
        });

      const options = {
        clientSecret: this.stripeClientSecret,
        // Fully customizable with appearance API.
        appearance: {
          theme: "stripe",
        },
      };

      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
      this.elements = this.stripe.elements(options);

      // Create and mount the Payment Element
      const paymentElement = this.elements.create("payment");

      paymentElement.mount(this.$refs.paymentElement);

      setTimeout(() => {
        this.$refs.paymentElement.scrollIntoView({
          behavior: "smooth",
        });
      }, 300);
    },
    async loadPaymentMethods() {
      try {
        this.isLoading = true;
        await this.getStripePaymentMethods();
        this.isLoading = false;
        this.isLoadedPaymentMethods = true;
      } catch (error) {
        this.isLoading = false;
        console.log("Payment list error", error);
      }
    },
    submitPayuForm() {
      try {
        let element = document.createElement("div");
        element.innerHTML = this.orderData.payuForm;

        document.body.append(element);

        let form = document.getElementById("payUForm");
        form.submit();

        // let res = Vue.compile(this.testHTML);
        // console.log(res.);
        // console.log(form);
      } catch (error) {
        console.log("Submit form error", error);
        return Promise.reject({
          title: this.$t("unexpected_error"),
          source: "post-order",
        });
      }
    },
  },
  async mounted() {
    if (this.killerDeal) {
      console.log("KILLER DEAL !!!");
      this.isLoading = false;
      return;
    }

    if (this.paymentProcessor === "PayU") {
      this.isLoading = false;
      return;
    }

    await this.loadPaymentMethods();
  },
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;

.payment-step {
  @media screen and (max-width: $mobile-view) {
    background-color: #ffffff;
    padding-top: 40px;
    padding-left: 24px;
    padding-right: 24px !important;
  }
  .hidden-slot {
    display: none;
  }
  .component-title {
    line-height: 52px;
    margin-bottom: 60px;
    @media screen and (max-width: $mobile-view) {
      display: none;
    }
  }
  .component-subtitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;

    margin-bottom: 24px;
    @media screen and (max-width: $mobile-view) {
      font-size: 20px;
      line-height: 21px;

      margin-bottom: 10px;
    }
  }
  .killer-deal {
    background-color: #f6f6f6;
    font-size: 14px;
    font-weight: 600;
    height: 105px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $mobile-view) {
      height: 166px;
      flex-direction: column;
      margin-top: 24px;
      margin-bottom: 10px;
    }
    img {
      margin-right: 22px;
      @media screen and (max-width: $mobile-view) {
        margin-right: 0;
        margin-bottom: 26px;
      }
    }
  }
  .payu-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 106px;
    background-color: #f6f6f6;
    color: #000;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media screen and (max-width: $mobile-view) {
      flex-direction: column;
      height: 166px;
    }
    img {
      margin-right: 24px;
      @media screen and (max-width: $mobile-view) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }
    .form-title {
      text-align: center;
      @media screen and (max-width: $mobile-view) {
        max-width: 232px;
      }
    }
  }
  .payment-form {
    display: grid;
    padding-bottom: 20px;
    // min-height: 600px;
    @media screen and (max-width: $mobile-view) {
      // min-height: auto;
    }
  }
}
</style>